import { sendToRaygun } from '@/utils/raygun';

export class ReferralsApi {
    appStatus: AppStatus = 'notRequested';
    isEmbedded = false;
    isModalOpen = false;
    openModalSubject = new ReferralsApiSubject();
    loadedModalSubject = new ReferralsApiSubject();
    closeModalSubject = new ReferralsApiSubject();

    constructor({ isEmbedded, openModalObservers, loadedModalObservers, closeModalObservers }: ReferralsApiInitialParams) {
        if (openModalObservers) {
            this.openModalSubject.subscribe(openModalObservers);
        }

        if (loadedModalObservers) {
            this.loadedModalSubject.subscribe(loadedModalObservers);
        }

        if (closeModalObservers) {
            this.closeModalSubject.subscribe(closeModalObservers);
        }

        this.isEmbedded = isEmbedded;
    }

    openModal(): void {
        this.isModalOpen = true;
        this.openModalSubject.notify();
    }

    loadedModal(): void {
        this.appStatus = 'loaded';
        this.loadedModalSubject.notify();
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.closeModalSubject.notify();
    }
}

class ReferralsApiSubject {
    _observers: ReferralsApiObserver[] = [];

    subscribe(observer: ReferralsApiObserver | ReferralsApiObserver[]): void {
        const observerArray = (observer instanceof Array) ? observer : [observer];
        this._observers.push(...observerArray);
    }

    unsubscribe(observerToRemove: ReferralsApiObserver): void {
        this._observers = this._observers.filter(observer => observer !== observerToRemove);
    }

    notify(): void {
        for (const observer of this._observers) {
            try {
                observer();
            }
            catch (error) {
                sendToRaygun(error, { info: (error as Error)?.message });
            }
        }
    }
}

export interface ReferralsApiInitialParams {
    isEmbedded: boolean;
    openModalObservers?: ReferralsApiObserver[];
    loadedModalObservers?: ReferralsApiObserver[];
    closeModalObservers?: ReferralsApiObserver[];
}
export type AppStatus = 'notRequested' | 'requested' | 'loaded';
export type ReferralsApiObserver = () => void;
