import { ReferralsApi, ReferralsApiInitialParams } from '@/referralsApi/ReferralsApi';

export function initialiseReferralsApi(initialParams: ReferralsApiInitialParams): ReferralsApi | undefined {
    window.okeReferralsApi = new ReferralsApi(initialParams);
    return getReferralsApi();
}

export function getReferralsApi(): ReferralsApi | undefined {
    return window.okeReferralsApi;
}
