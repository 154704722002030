import { UTMData } from '@okendo/reviews-common';

export function getURLQueryParams(): QueryParams {
    return Object.fromEntries(
        new URLSearchParams(window.location.search)
    );
}

export function getUTMData(): UTMData | undefined {
    const queryParams = getURLQueryParams();
    const utmData: UTMData = {};
    if (queryParams.utm_source) {
        utmData.source = queryParams.utm_source;
    }

    if (queryParams.utm_medium) {
        utmData.medium = queryParams.utm_medium;
    }

    if (queryParams.utm_campaign) {
        utmData.campaign = queryParams.utm_campaign;
    }

    if (queryParams.utm_content) {
        utmData.content = queryParams.utm_content;
    }

    if (queryParams.utm_term) {
        utmData.term = queryParams.utm_term;
    }

    if (Object.keys(utmData).length) {
        return utmData;
    }
}

interface QueryParams {
    [k: string]: string;
}
