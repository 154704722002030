import { hideLoader, hideOverlay, showOverlay } from '@/referralsApi/overlay';
import { ReferralsApiInitialParams } from '@/referralsApi/ReferralsApi';
import { sendToRaygun } from '@/utils/raygun';
import { getReferralsApi, initialiseReferralsApi } from '@/utils/referralsApiUtil';
import { getURLQueryParams } from '@/utils/urlUtil';

const embeddedWidgetElementId = '[data-oke-referrals-widget]';

let referralsChunkUrls: string[];
if (!process.env['BASE_URL']) {
    referralsChunkUrls = [
        `/js/referrals-app.js`
    ];
}
else {
    referralsChunkUrls = [
        `${process.env['VUE_APP_REFERRALS_BASE_URL']}/js/referrals-app.js`,
        `${process.env['VUE_APP_REFERRALS_BASE_URL']}/css/referrals-app.css`
    ];
}

setupReferralsApi();

function setupReferralsApi(): void {
    const isEmbedded = !!document.querySelector(embeddedWidgetElementId);
    const initialParams: ReferralsApiInitialParams = {
        openModalObservers: [requestApp],
        isEmbedded
    };

    const referralsApi = initialiseReferralsApi(initialParams);
    if (!referralsApi) {
        sendToRaygun(new Error('Referrals API failed to initialise'), { info: initialParams });
        return;
    }

    if (!isEmbedded) {
        referralsApi.openModalSubject.subscribe(showOverlay);
        referralsApi.closeModalSubject.subscribe(hideOverlay);
        referralsApi.loadedModalSubject.subscribe(hideLoader);
    }

    if (isEmbedded || hasRecipientFlowQueryParams()) {
        referralsApi.openModal();
    }

    setupCustomButtons();
}

function setupCustomButtons(): void {
    const customButtons = document.querySelectorAll('[data-oke-open-referrals], .oke-open-referrals');
    const onClick = () => {
        const referralsApi = getReferralsApi();
        referralsApi?.openModal();
    };

    for (const button of customButtons) {
        (button as HTMLElement).addEventListener('click', onClick);
    }
}

function hasRecipientFlowQueryParams(): boolean {
    const { referralSenderId, referralId } = getURLQueryParams();
    return !!referralSenderId || !!referralId;
}

function requestApp(): void {
    const referralsApi = getReferralsApi();
    if (referralsApi?.appStatus !== 'notRequested') {
        // App has already been requested
        return;
    }

    referralsApi.appStatus = 'requested';

    for (const url of referralsChunkUrls) {
        addElementToPage(url);
    }

    referralsApi.openModalSubject.unsubscribe(requestApp);
}

function addElementToPage(url: string): void {
    if (/.js/.test(url)) {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = url;
        document.head.appendChild(scriptTag);
    }
    else if (/.css/.test(url)) {
        const cssTag = document.createElement('link');
        cssTag.type = 'text/css';
        cssTag.rel = 'stylesheet';
        cssTag.href = url;
        document.head.appendChild(cssTag);
    }
}

// Expose global function for components to load captcha on mounted() hook
window.okeInitialiseCaptcha = (callback: () => void) => {
    if (typeof window.grecaptcha?.ready !== 'function') {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        scriptTag.onload = callback;
        document.head.appendChild(scriptTag);
    }
    else {
        callback();
    }
};
