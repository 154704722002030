import { getReferralsApi } from '@/utils/referralsApiUtil';

const visibleOverlayClassName = 'c-oke-modalOverlay--visible';
const overlayClassName = 'c-oke-modalOverlay';
const overlayId = 'okeModalOverlay';

const isLoadingClassName = 'c-oke-modal-isLoading';
const loaderContainerClassName = 'c-oke-modalOverlay-loaderContainer';
const loaderContainerId = 'okeModalLoaderContainer';

const loaderClassName = 'c-oke-modalOverlay-loader';

const overlayCss =
    `.${visibleOverlayClassName}.${overlayClassName} {
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100vw;
        z-index: 1000000007;
    }
    .${visibleOverlayClassName} .${isLoadingClassName}.${loaderContainerClassName} {
        background-color: white;
        width: 600px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e5e5ec;
        border-radius: 4px;
        left: 50%;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .${visibleOverlayClassName} .${isLoadingClassName} .${loaderClassName} {
        display: inline-block;
        position: relative;
        height: 48px;
        width: 48px;
        vertical-align: middle;
    }
    .${visibleOverlayClassName} .${isLoadingClassName} .${loaderClassName}:after {
        border-radius: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        content: "";
        position: absolute;
        animation: spin 1.5s linear 0s infinite none normal;
        border: 6px solid transparent;
        border-right-color: #535565;
        border-top-color: #535565;
        border-left-color: #535565;
    }`;

export function showOverlay(): void {
    const overlayElement = document.getElementById(overlayId);
    if (overlayElement) {
        overlayElement.classList.add(visibleOverlayClassName);
    }
    else {
        createOverlay();
    }
}

export function hideOverlay(): void {
    const overlayElement = document.getElementById(overlayId);
    if (overlayElement) {
        overlayElement.classList.remove(visibleOverlayClassName);
    }
}

export function hideLoader(): void {
    const loaderContainerElement = document.getElementById(loaderContainerId);
    if (loaderContainerElement) {
        loaderContainerElement.classList.remove(isLoadingClassName);
    }
}

function createOverlay(): void {
    const cssElement = document.createElement('style');
    cssElement.innerHTML = overlayCss;
    document.head.appendChild(cssElement);

    const overlayElement = document.createElement('div');
    overlayElement.id = overlayId;
    overlayElement.classList.add(overlayClassName);
    overlayElement.classList.add(visibleOverlayClassName);
    overlayElement.addEventListener('click', closeModal);
    document.body.appendChild(overlayElement);

    const loaderContainerElement = document.createElement('div');
    loaderContainerElement.id = loaderContainerId;
    loaderContainerElement.classList.add(loaderContainerClassName);
    loaderContainerElement.classList.add(isLoadingClassName);
    overlayElement.appendChild(loaderContainerElement);

    const loaderElement = document.createElement('span');
    loaderElement.classList.add(loaderClassName);
    loaderContainerElement.appendChild(loaderElement);
}

function closeModal(): void {
    const referralsApi = getReferralsApi();
    referralsApi?.closeModal();
}
