let rg4js = (...args: any) => args;

export const raygunLite = (() => {
    let apiKey = '';
    let tags: string[] = [];
    let version = '';

    return (method: string, args: any) => {
        if (method === 'apiKey') {
            apiKey = args;
        }
        else if (method === 'withTags') {
            tags = args;
        }
        else if (method === 'setVersion') {
            version = args;
        }
        else if (method === 'send') {
            /*
                See documentation below for options available on the POST body:
                https://raygun.com/documentation/product-guides/crash-reporting/api/
            */
            const { error, customData } = args as { error: string, customData: Record<string, unknown> };
            const body = safeStringify({
                occurredOn: new Date().toISOString(),
                details: {
                    tags,
                    version,
                    error: {
                        data: {
                            customData: {
                                ...customData,
                                referralDetails: window.referralDetails,
                                referralsApi: window.okeReferralsApi,
                                externalScripts: getExternalScripts()
                            }
                        },
                        message: error
                    },
                    request: {
                        url: window.location.href
                    },
                    environment: {
                        'browser-Version': navigator.userAgent
                    }
                }
            });
            fetch(`https://api.raygun.com/entries`, {
                method: 'POST',
                headers: { 'X-ApiKey': apiKey },
                body
            });
        }
    };
})();

function getExternalScripts(): Record<string, any>[] {
    try {
        // The nextHopProtocol property can have an empty string if the resource is a cross-origin request and no
        // Timing-Allow-Origin HTTP response header is used. Our init and capture scripts fall into this category.
        const entries = performance.getEntriesByType('resource') as PerformanceResourceTiming[];
        const crossOriginScripts = entries.filter(entry => entry.initiatorType === 'script' && !entry.nextHopProtocol);

        const externalScripts = [];
        for (const { name, startTime, duration } of crossOriginScripts) {
            externalScripts.push({ name, startTime, duration });
        }

        // Cap list to 100 entries to limit post body size.
        return [...externalScripts.slice(0, 100)];
    }
    catch {
        return [];
    }
}

function safeStringify(record: Record<string, unknown>): string {
    const circularCache: Record<string, unknown>[] = [];
    return JSON.stringify(record, (_key, value) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            if (circularCache.includes(value)) {
                return `Nested object with ${Object.keys(value).length} keys hidden.`;
            }
            circularCache.push(value);
        }
        return value;
    });
}

export function configureRaygun(rg4jsModule: any, entryBundle: string) {
    rg4js = rg4jsModule;

    rg4js('apiKey', process.env.VUE_APP_RAYGUN_API_KEY!);
    rg4js('withTags', ['referrals', process.env.VUE_APP_ENVIRONMENT!, entryBundle]);
    rg4js('setVersion', require('../../package.json').version);
    rg4js('enableCrashReporting', true);
    // rg4js('options', {
    //     debugMode: process.env.NODE_ENV !== 'production',
    //     ignore3rdPartyErrors: true
    // });
}

export function getErrorMessage(err: unknown): string | undefined {
    return (err as Error)?.message;
}

export async function sendToRaygun(err: any, { vm, info }: Record<string, unknown>): Promise<void> {
    const errorDetails = await buildErrorDetails(err);

    rg4js('send', {
        error: errorDetails.label,
        customData: { vm, info, errorDetails }
    });
}

export function isFailedToFetchError(err: any): boolean {
    return typeof err === 'object' && err && 'message' in err && err.message === 'Failed to fetch';
}

async function buildErrorDetails(err: any): Promise<Record<string, any>> {
    if (isError(err)) {
        return {
            label: err.message,
            stack: err.stack
        };
    }
    else if (isResponse(err)) {
        const responseBody = await err.json() as { error: { description: string } };
        return {
            label: getResponseLabel(err, responseBody.error.description),
            responseBody
        };
    }
    else {
        return {
            label: 'Unknown Error',
            rawError: (await err) ?? 'no details could be retrieved'
        };
    }
}

function isError(err: any): err is Error {
    return err?.message !== undefined;
}

function isResponse(err: any): err is Response {
    return err?.json !== undefined;
}

function getResponseLabel(response: Response, errorMessage: string): string {
    // Hide all GUIDs and product ID numbers etc to keep the error group label generic
    const genericUrl = response.url
        .replaceAll(/([\w]+-){4}[\w]+/ig, 'guid')
        .replaceAll(/(\d){4,}/g, 'digits');

    return `${genericUrl}: ${response.status} ${errorMessage}`;
}
